import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import {
	Alert,
	FullImage,
	PrivacyPolicy,
	ProductHasVariants,
	ProductVariantNotExist,
	Terms,
	VideoPlayer,
} from '@/components/portals';

export const PortalsProvider: FC<PropsWithChildren> = ({ children }) => (
	<>
		<Alert />
		<Terms />
		<PrivacyPolicy />
		<VideoPlayer />
		<ProductHasVariants />
		<ProductVariantNotExist />
		<FullImage />
		{children}
	</>
);
