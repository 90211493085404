import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { toggleBodyScrolling } from '@/shared/utils';

import type {
	AlertProps,
	FullImageProps,
	Portal,
	ProductHasVariantsProps,
	ProductVariantNotExistProps,
	VideoPlayerProps,
} from './portals.types';

export type PortalsState = {
	Alert: Portal<AlertProps>;
	Terms: Portal;
	PrivacyPolicy: Portal;
	VideoPlayer: Portal<VideoPlayerProps>;
	ProductHasVariants: Portal<ProductHasVariantsProps>;
	ProductVariantNotExist: Portal<ProductVariantNotExistProps>;
	FullImage: Portal<FullImageProps>;
};

const initialState: PortalsState = {
	Alert: {
		open: false,
		props: {
			text: null,
			mode: null,
		},
	},
	Terms: {
		open: false,
	},
	PrivacyPolicy: {
		open: false,
	},
	VideoPlayer: {
		open: false,
		props: {
			videoId: null,
		},
	},
	ProductHasVariants: {
		open: false,
		props: {
			product: null,
		},
	},
	ProductVariantNotExist: {
		open: false,
		props: {
			product: null,
			selectedVariant: null,
		},
	},
	FullImage: {
		open: false,
		props: {
			blob: null,
		},
	},
};

export const portalsSlice = createSlice({
	name: 'portals',
	initialState,
	reducers: {
		toggleAlert: (state, action: PayloadAction<Portal<AlertProps>>) => {
			state.Alert = action.payload;
		},
		toggleTerms: (state, action: PayloadAction<Portal>) => {
			state.Terms = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
		togglePrivacyPolicy: (state, action: PayloadAction<Portal>) => {
			state.PrivacyPolicy = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
		toggleVideoPlayer: (state, action: PayloadAction<Portal<VideoPlayerProps>>) => {
			state.VideoPlayer = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
		toggleProductHasVariants: (state, action: PayloadAction<Portal<ProductHasVariantsProps>>) => {
			state.ProductHasVariants = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
		toggleProductVariantNotExist: (state, action: PayloadAction<Portal<ProductVariantNotExistProps>>) => {
			state.ProductVariantNotExist = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
		toggleFullImage: (state, action: PayloadAction<Portal<FullImageProps>>) => {
			state.FullImage = action.payload;

			toggleBodyScrolling(action.payload.open);
		},
	},
});

export const {
	toggleAlert,
	toggleTerms,
	togglePrivacyPolicy,
	toggleVideoPlayer,
	toggleProductHasVariants,
	toggleProductVariantNotExist,
	toggleFullImage,
} = portalsSlice.actions;
