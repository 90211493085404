import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { LoadingWrapper } from '@/components/shared';
import { noop } from '@/shared/constants';

import type { ButtonMode, ButtonProps } from './Button.types';

const defaultClasses =
	'inline-block font-medium m-0 relative rounded-xl tablet:rounded-2xl text-lg text-white transition-all';

const defaultSizeClasses =
	'min-w-[16rem] min-h-[3rem] py-2 px-4 tablet:min-w-[20rem] tablet:text-2xl tablet:min-w-[22rem] tablet:py-4';

const getModeStyle = (mode: ButtonMode, disabled: boolean): string => {
	switch (mode) {
		case 'orange':
			if (disabled) {
				return 'bg-orange-100/50';
			}
			return 'bg-orange-100';
		case 'yellow':
			if (disabled) {
				return 'bg-yellow-100/50';
			}
			return 'bg-yellow-100';
		case 'red':
			if (disabled) {
				return 'bg-red-100/50';
			}
			return 'bg-red-100';
		case 'pink':
			if (disabled) {
				return 'bg-pink-300/50';
			}
			return 'bg-pink-200';
		default:
			return '';
	}
};

export const Button: FC<ButtonProps> = ({
	children,
	type = 'button',
	onClick = noop,
	mode = 'orange',
	variant,
	disabled = false,
	loading = false,
	className = '',
	to,
}) => {
	const classes = clsx(
		defaultClasses,
		variant !== 'small' && defaultSizeClasses,
		!(disabled || loading) && 'active:scale-95',
		getModeStyle(mode, disabled),
		className,
	);

	const btnProps = {
		type,
		onClick,
		disabled: disabled || loading,
		className: classes,
	};

	const buttonContent = () => (
		<button {...btnProps}>
			<LoadingWrapper condition={loading} classes="w-[24px] h-[24px] border-[4px]">
				{children}
			</LoadingWrapper>
		</button>
	);

	if (to) {
		if (disabled) {
			return buttonContent();
		}
		return (
			<Link to={to} className={clsx('block w-fit', loading && 'pointer-events-none')}>
				{buttonContent()}
			</Link>
		);
	}

	return buttonContent();
};
