import { CONFIG } from '@/shared/config';

export const noop = () => {};

export const MEDIAS_LIST_LENGTH = 5;

export const AVAILABLE_TICKET_TIMES = [
	'10:00',
	'10:30',
	'11:00',
	'11:30',
	'12:00',
	'12:30',
	'13:00',
	'13:30',
	'14:00',
	'14:30',
	'15:00',
	'15:30',
	'16:00',
	'16:30',
	'17:00',
	'17:30',
	'18:00',
	'18:30',
];

export const shortWeekdays = {
	eng: ['', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
	ru: ['', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
};

export const getStaticPath = ({ type, path }: { type: StaticType; path: string }) =>
	`${CONFIG.BASE_API_URL}/static/${type}/${encodeURIComponent(path)}`;

export const getYoutubeCover = ({ videoId }: { videoId: string }) =>
	`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
